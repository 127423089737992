import { classNames } from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { useLayoutStore } from "@store/layoutStore";
import Router from "next/router";
import { shallow } from "zustand/shallow";

/* eslint-disable @next/next/no-img-element */
type Props = {};
export const ModalMinCheckin = ({}: Props) => {
  const { getLangLabel } = useWhiteLabelLan();

  const { showModalMinCheckin } = useLayoutStore(
    (state) => ({
      showModalMinCheckin: state.showModalMinCheckin,
    }),
    shallow
  );
  const { setShowModalMinCheckin } = useLayoutStore();

  const handleClose = () => {
    setShowModalMinCheckin(false);
  };

  const redirectToChangeDates = () => {
    handleClose();
    const pathname = window.location.pathname;
    //remove home or getfirst part of the path

    const home = pathname.split("/")[1];
    if (home === "") {
      Router.push("/");
      return;
    } else {
      const newPath = `/${home}`;
      //const newPath = pathname.replace(`/${home}`, "");
      Router.push(newPath);
    }
  };
  return (
    <div
      id="static-modal-plans"
      data-modal-backdrop="static"
      tabIndex={-1}
      aria-hidden="true"
      className={classNames(
        "tw-overflow-y-auto tw-bg-[var(--var-inspira--bk-purple)] tw-overflow-x-hidden tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-z-50 tw-flex tw-justify-center tw-items-center tw-align-middle tw-w-full  md:tw-inset-0 tw-h-[calc(100%)] tw-max-h-full lg:tw-pt-12",
        showModalMinCheckin ? "" : "tw-hidden"
      )}
    >
      <div className="tw-relative tw-w-full lg:tw-max-w-3xl tw-max-h-full">
        <div className=" tw-relative tw-bg-white tw-shadow tw-rounded-3xl lg:tw-mx-7 tw-mx-3">
          <div className="tw-relative tw-flex  tw-justify-end md:tw-justify-center tw-shadow-lg md:tw-shadow-none tw-w-full">
            <button
              type="button"
              onClick={handleClose}
              className="tw-absolute -tw-top-2 md:-tw-right-3 tw-m-7 tw-text-2xl tw-text-gray-500 tw-shadow-2xl tw-rounded-full"
            >
              <img
                src="/images/PersonalWallet/CloseBlueModal.svg"
                alt="close"
                className="tw-w-6 tw-h-6 tw-rounded-full"
              />
            </button>
          </div>
          <div className=" tw-flex tw-flex-col tw-justify-center tw-align-middle tw-items-center tw-p-8  lg:tw-px-26 tw-mt-6 tw-gap-4">
            <h1 className="tw-text-left tw-text-3xl tw-text-orange-500 tw-font-bold tw-mb-4">
              {getLangLabel(
                "Sorry! We’re unable to process your request as your travel date is too soon."
              )}
            </h1>
            <div>
              <img
                src="/images/card/unavailable.svg"
                alt="exclamation"
                className="tw-mt-4 tw-mx-auto tw-w-[500px]"
              />
            </div>
            <p className="tw-text-center tw-text-lg tw-font-semibold tw-text-gray-800 tw-mt-4">
              {getLangLabel(
                'Unfortunately, the requested information is unavailable because your travel date is too soon. Please click the "Change Dates"; button below to select a later date.'
              )}
            </p>
            <button
              type="button"
              onClick={redirectToChangeDates}
              className="tw-bg-inspira-purple-600 tw-text-lg tw-text-white tw-font-semibold tw-py-3 tw-px-8 tw-mt-4 tw-rounded-full hover:tw-bg-inspira-purple-500 change-dates-button"
            >
              {getLangLabel("Change Dates")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
