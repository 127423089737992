import { getLangCodeFull, getCurrencyCode, getSymb } from "./helper";
type TypeCurrencyOptions = {
  maxDigits?: number;
  simbol?: boolean;
  minDigits?: number;
};
export const getCurrencyByLanguage = (
  price: number,
  { maxDigits = 2, simbol = true, minDigits = 0 }: TypeCurrencyOptions = {},
  currencyCode?: string
) => {
  const langCodeFull = getLangCodeFull();
  try {
    let formatFunction = new Intl.NumberFormat(
      langCodeFull == "es-ES" ? "en-US" : langCodeFull,
      {
        style: "currency",
        currency: currencyCode || getCurrencyCode(),
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits,
      }
    );
    let formattedPrice = formatFunction.format(price); // formated price in the current language
    let currencySymbol =
      formatFunction
        .formatToParts(price)
        .find((part) => part.type === "currency")?.value || "";
    const adjustedPrice = formattedPrice.replace(currencySymbol, "").trim();
    if (currencySymbol.includes("MX")) {
      currencySymbol = currencySymbol.replace("MX", "MXN");
    }
    if (simbol) {
      return `${currencySymbol}${adjustedPrice}`;
    }
    return `${adjustedPrice}`;
  } catch {
    if (!simbol) return price;
    return getSymb() + " " + price;
  }
};

export const currencyFormatting = (
  amount: number,
  withDecimals = false,
  withCurrency = true
) => {
  const mainCurrency = getSymb();
  const amountFormated = Number(
    amount % 1 > 0 ? amount.toFixed(2) : amount.toFixed(0)
  );

  const finalAmount = !withDecimals
    ? getCurrencyByLanguage(Math.round(Number(amountFormated)), {
        simbol: false,
      })
    : getCurrencyByLanguage(Number(amountFormated), { simbol: false });

  if (!withCurrency) {
    return `${finalAmount}`;
  }
  return `${mainCurrency}${finalAmount}`;
};

export const getPriceInDays = (price: number, daysNum = 1) => {
  if (daysNum > 0 && price > 0) {
    const priceInDays = price / daysNum;

    return getCurrencyByLanguage(Number(priceInDays));
  }
  return "";
};
