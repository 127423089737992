/* eslint-disable @next/next/no-img-element */
import { useCommonCheckoutStore } from "@store/commonCheckoutStore";
import { shallow } from "zustand/shallow";
import { useWhiteLabelLan } from "@hooks/useLayout";

export const DuplicateBookModal = () => {
  const { getLangLabel } = useWhiteLabelLan();
  const { setShowDuplicateBooking } = useCommonCheckoutStore();
  const { showDuplicateBooking } = useCommonCheckoutStore(
    (state) => ({
      showDuplicateBooking: state.showDuplicateBooking,
    }),
    shallow
  );

  const handleClose = () => {
    setShowDuplicateBooking(false);
  };

  // Manejador para detectar clics fuera del modal
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Solo cerrar si el clic fue directamente en el fondo (no en sus hijos)
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!showDuplicateBooking) return null;

  return (
    <div
      className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-inspira-purple-600 tw-bg-opacity-50"
      onClick={handleOutsideClick}
    >
      <div className="tw-relative tw-w-full tw-max-w-3xl tw-p-6 tw-py-8 tw-mx-auto tw-bg-white tw-rounded-2xl tw-shadow-xl">
        {/* Header with close button */}
        <div className="tw-flex tw-justify-end tw-items-center tw-mb-4">
          <button
            onClick={handleClose}
            className="tw-text-gray-500 hover:tw-text-gray-700 tw-transition-colors tw-duration-200"
          >
            <img
              src="/images/general/close-bgWhite.svg"
              alt="close"
              className="tw-w-10 tw-h-8 tw-rounded-full"
            />
          </button>
        </div>
        <div className="tw-flex tw-items-center tw-pb-3 tw-justify-center">
          <h3 className="tw-text-3xl tw-font-bold tw-text-orange-600">
            {getLangLabel("Guest Name Update Required")}
          </h3>
        </div>

        {/* Content */}
        <div className="tw-mb-6">
          <div className="tw-flex tw-items-center tw-justify-center tw-mb-4">
            <img
              src="/images/general/updateRequired.png"
              alt="No disponible"
              className="tw-w-auto tw-h-auto"
            />
          </div>
          <p className="tw-text-center tw-text-gray-700 tw-pt-3 tw-text-xl tw-mb-2 tw-px-4 lg:tw-px-16">
            {getLangLabel(
              `You currently have this reservation under the same name. To proceed, please update the guest's name by clicking the "Change Name" option.`
            )}
          </p>
        </div>

        {/* Footer with buttons */}
        <div className="tw-flex tw-justify-center tw-mb-5">
          <button
            onClick={handleClose}
            className="tw-px-5 tw-py-2 tw-rounded-full tw-bg-inspira-purple-500 tw-text-xl tw-text-white tw-font-semibold hover:tw-bg-inspira-purple-600 tw-transition-colors tw-duration-200"
          >
            {getLangLabel("Change Name")}
          </button>
        </div>
      </div>
    </div>
  );
};
