import { create } from "zustand";
interface CommonCheckoutState {
  isDisableCompleteButton: boolean;
  activeLoader: boolean;
  section: string;
  showDuplicateBooking: boolean;
  showCurrencyLanguage: boolean;
  setSection: (value: string) => void;
  setIsDisableCompleteButton: (val: boolean) => void;
  setActiveloader: (val: boolean) => void;
  setShowDuplicateBooking: (val: boolean) => void;
  setShowCurrencyLanguage: (val: boolean) => void;
}

export const useCommonCheckoutStore = create<CommonCheckoutState>((set) => ({
  isDisableCompleteButton: false,
  activeLoader: false,
  section: "",
  showDuplicateBooking: false,
  showCurrencyLanguage: false,
  setSection: (value: string) => set({ section: value }),
  setIsDisableCompleteButton: (value: boolean) =>
    set({ isDisableCompleteButton: value }),
  setActiveloader: (value: boolean) => set({ activeLoader: value }),
  setShowDuplicateBooking: (value: boolean) =>
    set({ showDuplicateBooking: value }),
  setShowCurrencyLanguage: (value: boolean) =>
    set({ showCurrencyLanguage: value }),
}));
