import { applyRelease } from "@lib/api/weeks/applyRelease";
import { checkCookies, getCookie, removeCookies } from "cookies-next";
import { useEffect } from "react";

export const useWeeksRelease = () => {
  useEffect(() => {
    try {
      if (checkCookies("holdTok")) {
        const pathname = window.location.pathname;
        let validUrl = [
          "/resorts/checkout",
          "/stays/checkout",
          "/resorts/checkout-success",
          "/stays/checkout-success",
        ];
        if (
          ["/resorts/checkout-success", "/stays/checkout-success"].includes(
            pathname
          )
        ) {
          removeCookies("holdTok");
        } else {
          if (checkCookies("holdTok") && !validUrl.includes(pathname)) {
            let holdToken = String(getCookie("holdTok"));
            let splitToken = holdToken.split("|");
            let countryUser = checkCookies("userCountryCode")
              ? String(getCookie("userCountryCode"))
              : "US";
            applyRelease({
              residency: countryUser,
              search_type: "country",
              reservationToken: splitToken[1],
              mainPath: splitToken[0] || "resorts",
            }).then((res) => {
              if (res) {
                removeCookies("holdTok");
              }
            });
          }
        }
      }
    } catch (error) {}

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
