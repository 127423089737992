import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import Script from "next/script";

export const TravelSocietyWidgetChat = () => {
  const { isEnabletravelsocietyChat } = useLayoutStore(
    (state) => ({
      isEnabletravelsocietyChat:
        state.externalConfig?.isEnabletravelsocietyChat,
    }),
    shallow
  );

  if (!isEnabletravelsocietyChat) {
    return <></>;
  }

  return (
    <>
      <div id="travelsociety-widget-ts">
        <Script
          id="travelsociety-widget-container"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: ` 
           window.ZOHOIM=window.ZOHOIM||function(a,b){ZOHOIM[a]=b;};window.ZOHOIM.prefilledMessage="";(function(){var d=document;var s=d.createElement('script');s.type='text/javascript';s.nonce='{place_your_nonce_value_here}';s.defer=true;s.src="https://im.zoho.com/api/v1/public/channel/c74ed587ba975e879b54b835963702d8/widget";d.getElementsByTagName('head')[0].appendChild(s); })()
            `,
          }}
        />
      </div>
    </>
  );
};
