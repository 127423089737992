import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { useCurrencyLangInfo, useWhiteLabelLan } from "@hooks/useLayout";
import { IMAGE_PATH } from "@helpers/config";
import { getSymb } from "@helpers/helper";

type Props = {
  selectedCurrency: string;
  onSelectCurrency?: (currency: CurrencyDetailType) => void;
  className?: string;
};

export const SelectCurrency: React.FC<Props> = (props) => {
  const { selectedCurrency, onSelectCurrency, className = "" } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<CurrencyDetailType | undefined>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCurrencies, setFilteredCurrencies] = useState<
    CurrencyDetailType[]
  >([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { currencies } = useCurrencyLangInfo();
  const { getLangLabel } = useWhiteLabelLan();

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        // Limpiar el término de búsqueda cuando se cierra el menú
        setSearchTerm("");
      }
    }

    // Usar 'mousedown' para capturar el evento antes de que otros elementos lo procesen
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Filtrar monedas basadas en el término de búsqueda
  useEffect(() => {
    if (currencies.length > 0) {
      if (searchTerm.trim() === "") {
        setFilteredCurrencies(currencies);
      } else {
        const filtered = currencies.filter(
          (currency) =>
            currency.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            currency.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCurrencies(filtered);
      }
    }
  }, [searchTerm, currencies]);

  // Find the selected currency in the currencies array
  useEffect(() => {
    const current = currencies.find(
      (currency) => currency.code === selectedCurrency
    );
    setSelected(current);
    setFilteredCurrencies(currencies);
  }, [selectedCurrency, currencies]);

  const updateCurrency = async (selectedCurrency: CurrencyDetailType) => {
    onSelectCurrency?.(selectedCurrency);
    setSelected(selectedCurrency);
    setIsOpen(false);
  };

  if (!selected || currencies.length === 0) {
    return null;
  }

  return (
    <div
      className={`tw-relative  tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full ${className}`}
      ref={dropdownRef}
    >
      <div className="tw-flex tw-flex-col tw-items-start tw-relative tw-justify-center tw-w-72">
        <label
          htmlFor="language"
          className="tw-text-inspira-txt-cardsubtitle tw-text-sm tw-font-normal tw-pl-2"
        >
          {getLangLabel("Currency")}:
        </label>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-80 tw-gap-3  tw-space-x-2 tw-text-inspira-txt-cardsubtitle tw-bg-white tw-rounded-md tw-px-3 tw-py-2 tw-border-2 tw-border-[#BFBFBF] hover:tw-border-gray-300 tw-transition-all"
        >
          <div className="tw-flex tw-items-center tw-gap-3">
            <div className="tw-w-6 tw-h-4 tw-relative tw-flex tw-text-base tw-font-normal tw-justify-center tw-items-center tw-align-middle tw-text-inspira-txt-cardsubtitle">
              <Image
                src={IMAGE_PATH + selected.flag}
                alt={selected.code}
                width={24}
                height={16}
                className="object-cover"
                onError={(e) => {
                  e.currentTarget.src =
                    "/images/flight/checkout/imgNotFound.png";
                }}
              />
            </div>
            <span className="">
              {getSymb(selected.code)}-{selected.code}
            </span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="6"
            viewBox="0 0 14 6"
            fill="none"
          >
            <path d="M7 6L13.0622 0H0.937822L7 6Z" fill="#4D4D4D" />
          </svg>
        </button>

        {isOpen && (
          <div className="tw-absolute tw-z-50  tw-top-16 tw-mt-1 tw-w-full tw-max-w-80 tw-bg-white tw-rounded-md tw-shadow-lg tw-overflow-hidden tw-border tw-border-gray-200">
            <div className="tw-p-3">
              <input
                ref={searchInputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={getLangLabel("Search currency")}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-inspira-brandcolor-background focus:tw-border-inspira-brandcolor-background"
                autoFocus
              />
            </div>
            <div className="tw-max-h-60 tw-overflow-y-auto tw-py-1 tw-px-3">
              {filteredCurrencies.length === 0 ? (
                <div className="tw-text-center tw-py-4 tw-text-gray-500">
                  {getLangLabel("No currencies found")}
                </div>
              ) : (
                filteredCurrencies.map((currency, index) => (
                  <button
                    key={`${currency.code}-${currency.name}-${index}`}
                    onClick={() => updateCurrency(currency)}
                    className={`tw-w-full tw-text-left tw-px-8 tw-my-1 tw-py-2 tw-flex tw-items-center tw-space-x-3 tw-rounded-full hover:tw-bg-inspira-brandcolor-background hover:tw-text-white tw-transition-colors ${
                      currency.code === selectedCurrency
                        ? "tw-bg-inspira-brandcolor-background tw-text-white"
                        : ""
                    }`}
                  >
                    <div className="tw-w-6 tw-h-4 tw-relative tw-overflow-hidden ">
                      <Image
                        src={IMAGE_PATH + currency.flag}
                        alt={currency.code}
                        width={24}
                        height={16}
                        className="object-cover"
                      />
                    </div>
                    <div className="tw-flex tw-flex-col">
                      <div className="tw-flex tw-items-center tw-space-x-2">
                        <span className="tw-font-medium">
                          {getSymb(currency.code)}
                        </span>
                        <span className="tw-text-sm ">{currency.code}</span>
                      </div>
                      <span className="tw-text-xs ">{currency.name}</span>
                    </div>
                  </button>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
