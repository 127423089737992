/* eslint-disable @next/next/no-img-element */
import { useCommonCheckoutStore } from "@store/commonCheckoutStore";
import { shallow } from "zustand/shallow";
import { useCurrencyLangInfo, useWhiteLabelLan } from "@hooks/useLayout";
import { SelectCurrency } from "@components/SelectCurrency";
import {
  getCurrencyCode,
  getLangCodeFull,
  setCurrencyCookies,
  setLangCookies,
} from "@helpers/helper";
import { SelectLanguage } from "@components/SelectLanguage";
import { useRouter } from "next/router";
import { useAuth } from "@contexts/AuthContext";
import { useMain } from "@contexts/mainContext";
import { updatePrefCurrLang } from "@lib/api/profile/updatePrefCurrLang";
import { useEffect, useState } from "react";

export const LangCurrencyModal = () => {
  const router = useRouter();
  const { currentUser } = useAuth();
  const { userType } = useMain();
  const { languages } = useCurrencyLangInfo();
  const { currencies } = useCurrencyLangInfo();
  const [selectedLanguage, setSelectedLanguage] =
    useState<LangDetailTypeApi | null>(null);
  const [selectedCurrency, setSelectedCurrency] =
    useState<CurrencyDetailType | null>(null);

  const { getLangLabel } = useWhiteLabelLan();
  const { setShowCurrencyLanguage } = useCommonCheckoutStore();
  const { showCurrencyLanguage } = useCommonCheckoutStore(
    (state) => ({
      showCurrencyLanguage: state.showCurrencyLanguage,
    }),
    shallow
  );

  const handleClose = () => {
    setShowCurrencyLanguage(false);
  };

  // Manejador para detectar clics fuera del modal
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Solo cerrar si el clic fue directamente en el fondo (no en sus hijos)
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const onSelectLanguage = (language: LangDetailTypeApi) => {
    setSelectedLanguage(language);
  };

  const onSelectCurrency = (currency: CurrencyDetailType) => {
    setSelectedCurrency(currency);
  };

  const onApply = (
    language: LangDetailTypeApi | null,
    currency: CurrencyDetailType | null
  ) => {
    if (!language || !currency) return;
    setLangCookies(language.name, language.code, language.id);
    setCurrencyCookies(currency.id, currency.name, currency.code);
    if (currentUser) {
      const objUpdate = {
        usr_type: userType || "2",
        preferred_language: language.id,
        preferred_currency: String(currency.id),
      };

      updatePrefCurrLang(objUpdate).then(() => router.reload());
    } else {
      router.reload();
    }

    handleClose();
  };

  useEffect(() => {
    if (languages.length > 0 && currencies.length > 0) {
      const langCode = getLangCodeFull();
      const currencyCode = getCurrencyCode();
      if (langCode && currencyCode) {
        const langDetail = languages.find((l) => l.code === langCode);
        const currencyDetail = currencies.find((c) => c.code === currencyCode);
        if (langDetail && currencyDetail) {
          setSelectedLanguage(langDetail);
          setSelectedCurrency(currencyDetail);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currencies]);

  if (!showCurrencyLanguage) return null;

  return (
    <div
      className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-px-4 lg:tw-px-0 tw-bg-inspira-bk-modal"
      onClick={handleOutsideClick}
    >
      <div className="tw-relative tw-w-full tw-max-w-[492px] tw-py-2 tw-mx-auto tw-bg-white tw-rounded-2xl tw-shadow-xl">
        {/* Header with close button */}
        <div className="tw-flex tw-justify-end tw-items-center tw-mb-4">
          <button
            onClick={handleClose}
            className="tw-text-brandcolorbutton hover:tw-text-brandcolorbutton tw-transition-colors tw-duration-200"
          >
            <img
              src="/images/general/close-bgWhite.svg"
              alt="close"
              className="tw-w-10 tw-h-8 tw-rounded-full"
            />
          </button>
        </div>
        <div className="tw-flex tw-items-center tw-pb-3 tw-justify-center">
          <h3 className="tw-text-xl tw-font-semibold tw-text-inspira-txt-cardsubtitle">
            {getLangLabel("Select your language & currency")}
          </h3>
        </div>

        {/* Content */}
        <div className="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-items-center tw-flex-col">
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
              <SelectLanguage
                selectedLang={selectedLanguage?.code || ""}
                onSelectLanguage={onSelectLanguage}
              />
              <SelectCurrency
                selectedCurrency={selectedCurrency?.code || ""}
                onSelectCurrency={onSelectCurrency}
              />
            </div>
          </div>
        </div>

        {/* Footer with buttons */}
        <div className="tw-flex tw-justify-center tw-mb-5 tw-mt-10">
          <button
            onClick={() => onApply(selectedLanguage, selectedCurrency)}
            className="tw-px-5 tw-w-full tw-max-w-56 tw-py-2 tw-rounded-full tw-bg-inspira-brandcolorbutton tw-text-base tw-text-white tw-font-bold hover:tw-bg-inspira-accentcolorbutton tw-transition-colors tw-duration-200"
          >
            {getLangLabel("Apply")}
          </button>
        </div>
      </div>
    </div>
  );
};
