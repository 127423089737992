import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { useCurrencyLangInfo, useWhiteLabelLan } from "@hooks/useLayout";

type Props = {
  selectedLang: string;
  onSelectLanguage?: (language: LangDetailTypeApi) => void;
  className?: string;
};

export const SelectLanguage: React.FC<Props> = (props) => {
  const { selectedLang, onSelectLanguage, className = "" } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<LangDetailTypeApi | undefined>();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLanguages, setFilteredLanguages] = useState<
    LangDetailTypeApi[]
  >([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { languages } = useCurrencyLangInfo();

  const { getLangLabel } = useWhiteLabelLan();

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        // Limpiar el término de búsqueda cuando se cierra el menú
        setSearchTerm("");
      }
    }

    // Usar 'mousedown' para capturar el evento antes de que otros elementos lo procesen
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Filtrar idiomas basados en el término de búsqueda
  useEffect(() => {
    if (languages.length > 0) {
      if (searchTerm.trim() === "") {
        setFilteredLanguages(languages);
      } else {
        const filtered = languages.filter(
          (language) =>
            language.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            language.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredLanguages(filtered);
      }
    }
  }, [searchTerm, languages]);

  // Encontrar el idioma seleccionado
  useEffect(() => {
    const current = languages.find(
      (language) => language.code === selectedLang
    );
    setSelected(current);
    setFilteredLanguages(languages);
  }, [selectedLang, languages]);

  const onSelect = (language: LangDetailTypeApi) => {
    setSelected(language);
    onSelectLanguage?.(language);
    setIsOpen(false);
  };

  const getImgSelected = (code?: string | null) => {
    try {
      if (code) {
        const codeSplit = code.split("-")[1];
        return `https://flagcdn.com/w40/${codeSplit.toLowerCase()}.png`;
      }
    } catch (error) {
      console.error(error);
    }

    return null;
  };
  if (!selected || languages.length === 0) {
    return null;
  }

  return (
    <div
      className={`tw-relative  tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full ${className}`}
      ref={dropdownRef}
    >
      <div className="tw-flex tw-flex-col tw-items-start tw-relative tw-justify-center tw-w-72">
        <label
          htmlFor="language"
          className="tw-text-inspira-txt-cardsubtitle tw-text-sm tw-font-normal tw-pl-2"
        >
          {getLangLabel("Language")}:
        </label>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="tw-flex tw-justify-between tw-items-center tw-w-full tw-max-w-80 tw-gap-3  tw-space-x-2 tw-text-inspira-txt-cardsubtitle tw-bg-white tw-rounded-md tw-px-3 tw-py-2 tw-border-2 tw-border-[#BFBFBF] hover:tw-border-gray-300 tw-transition-all"
        >
          <div className="tw-flex tw-items-center tw-gap-3">
            <span className="">{selected.name}</span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="6"
            viewBox="0 0 14 6"
            fill="none"
          >
            <path d="M7 6L13.0622 0H0.937822L7 6Z" fill="#4D4D4D" />
          </svg>
        </button>

        {isOpen && (
          <div className="tw-absolute tw-z-50  tw-top-16 tw-mt-1 tw-w-full tw-max-w-80 tw-bg-white tw-rounded-md tw-shadow-lg tw-overflow-hidden tw-border tw-border-gray-200">
            <div className="tw-p-3">
              <input
                ref={searchInputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={getLangLabel("Search language")}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-inspira-brandcolor-background focus:tw-border-inspira-brandcolor-background"
                autoFocus
              />
            </div>
            <div className="tw-max-h-60 tw-overflow-y-auto tw-py-1 tw-px-3">
              {filteredLanguages.length === 0 ? (
                <div className="tw-text-center tw-py-4 tw-text-gray-500">
                  {getLangLabel("No languages found")}
                </div>
              ) : (
                filteredLanguages.map((language, index) => {
                  return (
                    <button
                      key={`${language.code}-${language.name}-${index}`}
                      onClick={() => onSelect(language)}
                      className={`tw-w-full tw-text-left tw-px-8 tw-my-1 tw-py-2 tw-flex tw-items-center tw-space-x-3 tw-rounded-full hover:tw-bg-inspira-brandcolor-background hover:tw-text-white tw-transition-colors ${
                        language.code === selectedLang
                          ? "tw-bg-inspira-brandcolor-background tw-text-white"
                          : ""
                      }`}
                    >
                      <div className="tw-w-6 tw-h-4 tw-relative tw-overflow-hidden ">
                        <Image
                          src={getImgSelected(language.code) || ""}
                          alt={language.code}
                          width={24}
                          height={16}
                          className="object-cover"
                          onError={(e) => {
                            e.currentTarget.src =
                              "/images/flight/checkout/imgNotFound.png";
                          }}
                        />
                      </div>
                      <div className="tw-flex tw-flex-col">
                        <div className="tw-flex tw-items-center tw-space-x-2">
                          <span className="tw-text-sm ">{language.code}</span>
                        </div>
                        <span className="tw-text-xs ">{language.name}</span>
                      </div>
                    </button>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
